


















import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { apiService } from '@/services/api-services'
import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class FractalCallbackPage extends Vue {
  walletStore = walletStore
  loaded = false
  async mounted() {
    try {
      if (!walletStore.jwt) {
        snackController.error('Please login before checking KYC')
        return
      }
      const code = this.$route.query.code as string
      if (code && code !== 'undefined') {
        await apiService.fractalUsers.fractalKycOauth(code)
      }
      this.$router.replace('/')
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.loaded = true
    }
  }

  backToHome() {
    this.$router.replace('/')
  }
}
